<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block/>

        {{ page_title }}

      </h3>
    </div>

    <div class="box-body">
      <vue-tabs class="panel">
        <v-tab key="0" title="Общая" class="nav-tab-content">
          <transition name="fade">
            <div class="form-group" v-if="onProgress === false">

              <div class="row">
                <label class="col-xl-3 col-md-6" for="filter-by-branch" style="margin-bottom: 10px;">
                  <select class="form-control" id="filter-by-branch" v-on:change="setFilterByBranch($event)">
                    <option :value="null" class="text-muted">
                      Филиал
                    </option>
                    <option :value="branch.id" v-for="branch in branches">
                      {{ branch.title }}{{ branch.address ? ', ' + branch.address : '' }}
                    </option>
                  </select>
                </label>

                <label class="col-xl-3 col-md-6" for="filter-by-mark" style="margin-bottom: 10px;">
                  <select class="form-control" id="filter-by-mark" v-on:change="setFilterByMark($event)">
                    <option :value="null" class="text-muted">
                      Марка
                    </option>
                    <option :value="mark.id"
                            v-for="mark in marks">
                      {{ mark.name }}
                    </option>
                  </select>
                </label>

                <label class="col-xl-3 col-md-6" for="filter-by-model" style="margin-bottom: 10px;">
                  <select class="form-control" id="filter-by-model" :disabled="!models || models.length === 0"
                          v-on:change="setFilterByModel($event)">
                    <option :value="null" class="text-muted">
                      Модель
                    </option>
                    <option :value="model.id"
                            v-for="model in models">
                      {{ model.name }}
                    </option>
                  </select>
                </label>

                <label class="col-xl-3 col-md-6" for="filter-by-color" style="margin-bottom: 10px;">
                  <select class="form-control" id="filter-by-color" v-on:change="setFilterByColor($event)">
                    <option :value="null" class="text-muted">
                      Цвет
                    </option>
                    <option :value="color.id"
                            v-for="color in colors">
                      {{ color.title }}
                    </option>
                  </select>
                </label>
              </div>

              <div class="row">
                <label class="col-xl-3 col-md-6" style="margin-bottom: 10px;">
                  <span class="input-group">
                    <span class="input-group-text text-muted">Год</span>
                    <input :max="2023"
                           :min="1900"
                           class="form-control"
                           name="year_min"
                           type="number"
                           placeholder="От"
                           title="Год выпуска, от"
                           aria-label="Год выпуска, от"
                           v-on:input="setFilterByYears($event, 'min')">
                    <input :max="2023"
                           :min="1900"
                           class="form-control"
                           name="year_max"
                           type="number"
                           placeholder="До"
                           title="Год выпуска, до"
                           aria-label="Год выпуска, до"
                           v-on:input="setFilterByYears($event, 'max')">
                  </span>
                </label>

                <label class="col-xl-3 col-md-6" style="margin-bottom: 10px;">
                  <span class="input-group">
                    <span class="input-group-text text-muted">Цена, $</span>
                    <input :max="999999999"
                           :min="1"
                           class="form-control"
                           name="price_min"
                           type="number"
                           placeholder="От"
                           title="Стоимость, от $"
                           aria-label="Стоимость, от $"
                           v-on:input="setFilterByPrices($event, 'min')">
                    <input :max="999999999"
                           :min="1"
                           class="form-control"
                           name="price_max"
                           type="number"
                           placeholder="До"
                           title="Стоимость, до $"
                           aria-label="Стоимость, до $"
                           v-on:input="setFilterByPrices($event, 'max')">
                  </span>
                </label>

                <label class="col-xl-3 col-md-6" style="margin-bottom: 10px;">
                  <span class="input-group">
                    <span class="input-group-text text-muted">Пробег</span>
                    <input :max="9999999"
                           :min="1"
                           class="form-control"
                           name="race_min"
                           type="number"
                           placeholder="От"
                           title="Пробег, от км."
                           aria-label="Пробег, от км."
                           v-on:input="setFilterByRaces($event, 'min')">
                    <input :max="9999999"
                           :min="1"
                           class="form-control"
                           name="race_max"
                           type="number"
                           placeholder="До"
                           title="Пробег, до км."
                           aria-label="Пробег, до км."
                           v-on:input="setFilterByRaces($event, 'max')">
                  </span>
                </label>

                <label class="col-xl-3 col-md-6" style="margin-bottom: 10px;">
                  <span class="input-group">
                    <span class="input-group-text text-muted">Двигатель, л.</span>
                    <input :min="0.1"
                           step=".1"
                           class="form-control"
                           name="engine_min"
                           type="number"
                           placeholder="От"
                           title="Объем двигателя, от л."
                           aria-label="Объем двигателя, от л."
                           v-on:input="setFilterByEngines($event, 'min')">
                    <input :min="0.1"
                           step=".1"
                           class="form-control"
                           name="engine_max"
                           type="number"
                           placeholder="До"
                           title="Объем двигателя, до л."
                           aria-label="Объем двигателя, до л."
                           v-on:input="setFilterByEngines($event, 'max')">
                  </span>
                </label>
              </div>
              <div class="row">
                <div class="col-xl-3 col-md-6 col-sm-12 mb-1">
                  <input class="pointer margin-l-5"
                         style="transform: scale(1.2);"
                         id="filter-by-without-disabled"
                         type="checkbox"
                         v-on:change="setFilterByOnlyAvailable($event)"
                         :checked="true">
                  <label for="filter-by-without-disabled" class="margin-l-5 padding-l-10">
                    Только опубликованные
                  </label>
                </div>
              </div>
            </div>
            <loading-processing v-else></loading-processing>
          </transition>

          <card-items v-if="validateFilter"
                      :data_search="data_search"
                      :filters_query="filter_task"
                      :relations="relations"
                      component="car-task-item"
                      item_classes="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12 cars-grid-item"
                      api_resource="/cars">
          </card-items>
        </v-tab>
        <v-tab key="1" title="Детейлинг" class="nav-tab-content">
          <create-detailing></create-detailing>
        </v-tab>
        <v-tab key="2" title="Личная" class="nav-tab-content">
          <create-personal></create-personal>
        </v-tab>
        <v-tab key="3" title="На филиал/отдел" class="nav-tab-content">
          <create-on-branch></create-on-branch>
        </v-tab>
      </vue-tabs>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {VTab, VueTabs} from 'vue-nav-tabs';
import 'vue-nav-tabs/themes/vue-tabs.css';
import CardItems from './../../../components/pagination/card-items';
import BranchService from "@/services/BranchService";
import CreateDetailing from './create-detailing';
import CreatePersonal from './create-personal';
import CreateOnBranch from './create-on-branch';

Vue.component('car-task-item', (resolve) => {
  return require(['./car-item'], resolve);
});

export default {
  name: 'task_cars',

  components: {
    VueTabs,
    VTab,
    CardItems,
    CreateDetailing,
    CreatePersonal,
    CreateOnBranch,
  },

  props: {
    data_search: {
      type: String,
      required: false
    }
  },

  metaInfo() {
    return {
      title: this.page_title,
    }
  },

  data: () => ({
    page_title: 'Создать задачу',

    branches: [],
    marks: [],
    models: [],
    colors: [],

    relations: [
      'branch',
      'specification.gearbox',
      'specification.fuel',
      'specification.color',
      'specification.transmission',
      'sold',
      'creator.branch',
    ],

    filter_task: {
      branch_id: null,
      mark_id: null,
      model_id: null,
      color_id: null,
      year_min: null,
      year_max: null,
      price_min: null,
      price_max: null,
      race_min: null,
      race_max: null,
      engine_min: null,
      engine_max: null,
      without_disabled: true,
    },

    onProgress: true,
  }),

  async created() {
    try {
      const [marks_response, colors_response] = await axios.all([
        API.apiClient.get('/cars/marks?available=1', {
          params: {
            is_paginated: 0,
            sorting: 'asc',
            sort_column: 'name',
            filters_query: {
              only_available: true,
            },
          }
        }),
        API.apiClient.get('/cars/colors'),
      ]);

      this.branches = await BranchService.getItems();
      this.marks = marks_response.data;
      this.colors = colors_response.data;
    } catch (error) {
      errorHandler(error);
    }

    this.onProgress = false;
  },

  computed: {
    validateFilter: function () {
      return this.filter_task.branch_id || this.filter_task.mark_id || this.filter_task.model_id ||
          this.filter_task.color_id || this.filter_task.year_min || this.filter_task.year_max ||
          this.filter_task.price_min || this.filter_task.price_max || this.filter_task.race_min ||
          this.filter_task.race_max || this.filter_task.engine_min || this.filter_task.engine_max;
    },
  },

  methods: {
    async getCarModels(markId) {
      try {
        const models_response = await API.apiClient.get(
            '/cars/mark/' + markId + '/models', {
              params: {
                is_paginated: 0,
                sorting: 'asc',
                sort_column: 'name',
                filters_query: {
                  only_available: true,
                },
              }
            }
        );

        this.models = models_response.data;
      } catch (error) {
        errorHandler(error);
      }
    },

    setFilterByBranch(event) {
      this.filter_task.branch_id = event.target.value !== '' ? parseInt(event.target.value) : null;
      this.updateFilters();
    },
    setFilterByMark(event) {
      this.filter_task.mark_id = event.target.value !== '' ? parseInt(event.target.value) : null;

      this.models = [];

      if (this.filter_task.mark_id) {
        this.getCarModels(this.filter_task.mark_id);
      }
      this.updateFilters();
    },
    setFilterByModel(event) {
      this.filter_task.model_id = event.target.value !== '' ? parseInt(event.target.value) : null;
      this.updateFilters();
    },
    setFilterByColor(event) {
      this.filter_task.color_id = event.target.value !== '' ? parseInt(event.target.value) : null;
      this.updateFilters();
    },
    setFilterByYears(event, year_type) {
      let year = event.target.value !== '' ? parseInt(event.target.value) : null;

      if (year_type === 'min') {
        this.filter_task.year_min = year;
      } else if (year_type === 'max') {
        this.filter_task.year_max = year;
      }
      this.updateFilters();
    },
    setFilterByPrices(event, price_type) {
      let price = event.target.value !== '' ? parseInt(event.target.value) : null;

      if (price_type === 'min') {
        this.filter_task.price_min = price;
      } else if (price_type === 'max') {
        this.filter_task.price_max = price;
      }
      this.updateFilters();
    },
    setFilterByRaces(event, race_type) {
      let race = event.target.value !== '' ? parseInt(event.target.value) : null;

      if (race_type === 'min') {
        this.filter_task.race_min = race;
      } else if (race_type === 'max') {
        this.filter_task.race_max = race;
      }
      this.updateFilters();
    },
    setFilterByEngines(event, engine_type) {
      let engine = event.target.value !== '' ? parseFloat(event.target.value) : null;

      if (engine_type === 'min') {
        this.filter_task.engine_min = engine;
      } else if (engine_type === 'max') {
        this.filter_task.engine_max = engine;
      }
      this.updateFilters();
    },
    setFilterByOnlyAvailable(event) {
        this.filter_task.without_disabled = event.target.checked;
      this.updateFilters();
    },

    updateFilters() {
      this.EventBus.$emit('card-filter-changed');
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block/>

        {{ page_title }}
      </h3>
    </div>

    <div class="box-body">
      <transition name="fade">
        <div class="form-group">
          <div class="row">
            <label class="col-xl-2 col-md-6 col-sm-12 mb-1" for="filter-by-type">
              <multiselect :allow-empty="true"
                           :options="types"
                           :searchable="false"
                           id="filter-by-type"
                           @input="() => {
                             filter_task.type_id = type ? type.id : null;
                             updateFilters();
                           }"
                           deselect-label="Убрать"
                           label="name"
                           placeholder="Тип задачи"
                           required
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="Тип задачи"
                           track-by="id"
                           v-model="type">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
              </multiselect>
            </label>

            <div class="col-xl-3 col-md-6 col-sm-12 mb-1">
              <multiselect :allow-empty="true"
                           :options="cars"
                           :searchable="true"
                           id="filter-by-car"
                           @input="() => {
                             filter_task.car_id = car ? car.id : null;
                             updateFilters();
                           }"
                           deselect-label="Убрать"
                           label="title"
                           placeholder="Автомобиль"
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="Автомобиль"
                           track-by="id"
                           v-model="car">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
                <template slot="singleLabel" slot-scope="props">
                  #{{ props.option.id }} {{ props.option.title }}
                </template>
                <template slot="option" slot-scope="props">
                  #{{ props.option.id }} {{ props.option.title }}
                </template>
              </multiselect>
            </div>

            <div class="col-xl-1 col-md-6 col-sm-12 mb-1">
              <input class="form-control"
                     id="car_id"
                     name="car_id"
                     placeholder="ID авто"
                     title="ID авто"
                     type="number"
                     @input="updateFilters();"
                     v-model="filter_task.car_id">
            </div>

            <div class="col-xl-3 col-md-6 col-sm-12 mb-1">
              <multiselect :allow-empty="true"
                           :options="clients"
                           :searchable="true"
                           :show-labels="true"
                           id="filter-by-client"
                           @input="() => {
                             filter_task.client_id = client ? client.id : null;
                             updateFilters();
                           }"
                           deselect-label="Убрать"
                           label="title"
                           placeholder="Клиент"
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="Клиент"
                           track-by="id"
                           v-model="client">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
                <template slot="singleLabel" slot-scope="props">
                  {{ props.option.name }}, {{ props.option.phone }}
                </template>
<!--                <template slot="option" slot-scope="props">-->
<!--                  {{ props.option.name }}, <span class="text-muted">{{ props.option.phone }}</span>-->
<!--                </template>-->
              </multiselect>
            </div>

            <label class="col-xl-3 col-md-6 col-sm-12 mb-1" for="filter-by-responsible">
              <multiselect :allow-empty="true"
                           :options="employees"
                           :searchable="true"
                           id="filter-by-responsible"
                           @input="() => {
                             filter_task.responsible_id = responsible ? responsible.id : null;
                             updateFilters();
                           }"
                           deselect-label="Убрать"
                           label="title"
                           placeholder="Ответственный"
                           required
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="Ответственный"
                           track-by="id"
                           v-model="responsible">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
              </multiselect>
            </label>
          </div>
        </div>
      </transition>

      <card-items :data_search="data_search"
                  :filters_query="filter_task"
                  :relations="relations"
                  :relations_count="relations_count"
                  component="task-item"
                  item_classes="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 mb-4"
                  api_resource="/tasks">
      </card-items>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Multiselect from 'vue-multiselect';
import CardItems from './../../components/pagination/card-items';

Vue.component('task-item', (resolve) => {
  return require(['./item'], resolve);
});

export default {
  name: 'tasks',

  components: {
    Multiselect,
    CardItems,
  },

  props: {
    data_search: {
      type: String,
      required: false
    },
    data_type_ids: {
      type: String,
      required: false
    },
  },

  metaInfo() {
    return {
      title: this.page_title,
    }
  },

  data: () => ({
    page_title: 'Задачи',

    types: [],
    type: null,
    cars: [],
    car: null,
    clients: [],
    client: null,
    employees: [],
    responsible: null,

    relations: [
      'car.branch',
      'client',
      'type',
      'type_status',
      'responsible.branch',
      'branch',
      "department",
      'last_comment.creator',
      'creator',
      'editor',
    ],
    relations_count: [
      'comments',
      'files',
    ],

    filter_task: {
      type_id: null,
      car_id: null,
      client_id: null,
      responsible_id: null,
      type_ids: null,
    },
  }),

  async created() {
    try {
      if (this.data_type_ids) {
        this.filter_task.type_ids = this.data_type_ids;
      }

      const [
        types_response,
        cars_response,
        clients_response,
        employees_response
      ] = await axios.all([
        API.apiClient.get('/tasks/types'),
        API.apiClient.get('/cars', {
          params: this.$apiRequestParams.filters_list
        }),
        API.apiClient.get('/clients', {
          params: {
            "is_list_load": 1
          },
        }),
        API.apiClient.get('/employees', {
          params: this.$apiRequestParams.filters_list
        })
      ]);

      this.types = types_response.data;
      this.cars = cars_response.data;
      this.clients = clients_response.data;
      this.employees = employees_response.data;
    } catch (error) {
      errorHandler(error);
    }
  },

  computed: {},

  methods: {
    updateFilters() {
      this.EventBus.$emit('card-filter-changed');
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block/>

        {{ page_title }}

        <span v-if="car" class="text-muted font-scale" style="display: block; margin-left: 50px;">
          #{{ car.id }} {{ car.title }} -
          {{ car.creator.name }}, {{ car.creator.branch ? (car.creator.branch.title + ', ') : '' }}
          ID {{ car.creator.id }}
        </span>
      </h3>
    </div>

    <div class="box-body">
      <transition name="fade">
        <form @keydown="form.onKeydown($event)" @submit.prevent="saveTask" v-if="onProgress === false">

          <div class="row">
            <div class="col-xl-6 col-md-12">
              <div class="row mb-1" v-if="client">
                <div class="col-xl-6 col-md-12">
                  <a :href="'/client/' + client.id + '/details'" class="label label-success pointer" target="_blank">
                    <i class="fas fa-user-circle"></i>
                    Существующий клиент: ID <strong>{{ client.id }}, {{ client.name }}</strong> ({{ client.source_type}})
                  </a>
                </div>
                <div class="col-xl-6 col-md-12"></div>
              </div>
              <div class="row mb-1" v-else>
                <div class="col-xl-6 col-md-12">
                  <span class="label label-primary">
                    <i class="fas fa-user-circle"></i>
                    Новый клиент
                  </span>
                </div>
                <div class="col-xl-6 col-md-12"></div>
              </div>

              <div class="row">
                <div class="col-xl-6 col-md-12" v-bind:class="{ 'has-error': form.errors.has('client_name') }">
                  <div class="form-group">
                    <label for="client_name">
                      <input class="form-control"
                             :disabled="Boolean(form.client_id)"
                             id="client_name"
                             name="client_name"
                             placeholder="ФИО"
                             required
                             title="ФИО клиента"
                             type="text"
                             v-model="form.client_name">
                      <HasError :form="form" field="client_name"/>
                    </label>
                  </div>
                </div>

                <div class="col-xl-6 col-md-12" v-bind:class="{ 'has-error': form.errors.has('client_phone') }">
                  <div class="form-group">
                    <label for="client_phone">
                      <vue-tel-input id="client_phone"
                                     :disabled="Boolean(form.client_id)"
                                     v-on:input="checkPhoneOnExist"
                                     name="client_phone"
                                     v-bind='phone_config'
                                     v-model="form.client_phone">
                      </vue-tel-input>
                      <HasError :form="form" field="client_phone"/>
                    </label>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-6 col-md-12" v-bind:class="{ 'has-error': form.errors.has('type_status_id') }">
                  <div class="form-group">
                    <label for="type_status_id">
                      <label class="font-medium font-small">
                        Статус
                      </label>
                      <multiselect :allow-empty="false"
                                   :options="types_statuses"
                                   :searchable="false"
                                   id="type_status_id"
                                   @input="() => {
                                     form.type_status_id = type_status ? type_status.id : null;
                                   }"
                                   deselect-label="Убрать"
                                   label="name"
                                   placeholder="Статус"
                                   required
                                   select-label="Выбрать"
                                   selected-label="Выбрано"
                                   title="Статус"
                                   track-by="id"
                                   v-model="type_status">
                        <template slot="noResult">Не найдено</template>
                        <template slot="noOptions">Данные отсутствуют</template>
                      </multiselect>
                      <HasError :form="form" field="type_status_id"/>
                    </label>
                  </div>
                </div>

                <div class="col-xl-6 col-md-12" v-bind:class="{ 'has-error': form.errors.has('responsible_id') }">
                  <div class="form-group">
                    <label for="responsible_id">
                      <label class="font-medium font-small">
                        Ответственный
                      </label>
                      <multiselect :allow-empty="false"
                                   :options="employees"
                                   :searchable="false"
                                   id="responsible_id"
                                   @input="() => {
                                     form.responsible_id = responsible ? responsible.id : null;
                                   }"
                                   deselect-label="Убрать"
                                   label="title"
                                   placeholder="Ответственный"
                                   required
                                   select-label="Выбрать"
                                   selected-label="Выбрано"
                                   title="Ответственный"
                                   track-by="id"
                                   v-model="responsible">
                        <template slot="noResult">Не найдено</template>
                        <template slot="noOptions">Данные отсутствуют</template>
                      </multiselect>
                      <HasError :form="form" field="responsible_id"/>
                    </label>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12" v-bind:class="{ 'has-error': form.errors.has('type_id') }">
                  <div class="form-group">
                    <label for="type_id">
                      <label class="font-medium font-small">
                        Тип
                      </label>
                      <multiselect :allow-empty="false"
                                   :options="types"
                                   :searchable="false"
                                   id="type_id"
                                   @input="() => {
                                     form.type_id = type ? type.id : null;
                                     setTaskType();
                                   }"
                                   deselect-label="Убрать"
                                   label="name"
                                   placeholder="Тип задачи"
                                   required
                                   select-label="Выбрать"
                                   selected-label="Выбрано"
                                   title="Тип задачи"
                                   track-by="id"
                                   v-model="type">
                        <template slot="noResult">Не найдено</template>
                        <template slot="noOptions">Данные отсутствуют</template>
                      </multiselect>
                      <HasError :form="form" field="type_id"/>
                    </label>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12" v-bind:class="{ 'has-error': form.errors.has('comment') }">
                  <div class="form-group">
                    <textarea class="form-control"
                              maxlength="3000"
                              id="description"
                              rows="5"
                              placeholder="Комментарий"
                              v-model="form.comment">
                    </textarea>
                    <HasError :form="form" field="comment"/>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-6 col-md-12">
              <div class="panel panel-default mb-3">
                <div class="panel-heading" :style="car.sold ? 'background-color: rgba(40, 167, 69, 0.25) !important' : ''">
                  <h5 class="car-title mb-0">
                    #{{ car.id }}
                    <strong class="margin-l-5" style="position: absolute;">
                      {{ car.title }}
                    </strong>
                  </h5>
                  <span class="font-medium font-small mt-0">
                    <span v-if="car.owner_type === 'client'">Клиент</span>
                    <span v-if="car.owner_type === 'company'">Компания</span>
                    <span v-if="car.owner_type === 'exchange'">Обмен/выкуп</span>
                  </span>
                  <span class="label label-success font-medium font-small-xs" style="padding: 2px 3px; margin-left: 5px;">
                    {{ car.location === "owner" ? "У владельца" : "В салоне" }}
                  </span>
                </div>
                <div class="panel-body">
                  <div class="row">
                    <div class="col-12 col-lg-5">
                      <img v-if="car.main_photo" :src="car.main_photo" class="img-fluid" :alt="car.title"
                           style="min-height: 100px;">
                      <img :alt="car.title" class="img-fluid" src="../../../assets/img/no-car-photo-240-140.png" v-else>

                      <ul class="list-group list-group-unbordered">
                        <li class="list-group-item no-border pt-0 pb-1">
                          <strong class="font-scale-1">{{ car.price }} $</strong>
                          <small v-if="car.old_price && car.is_top_price"
                                 class="font-medium text-red text-muted margin-l-5 font-small">
                            {{ car.old_price }}$
                          </small>
                        </li>
                        <li class="list-group-item no-border medium-line-height text-muted font-small pb-1 pt-0"
                            v-if="[1, 2, 7, 8, 9].includes($store.getters['auth/authUser'].role.id) || car.creator.id !== $store.getters['auth/authUser'].id">
                          {{ car.created_date }}
                          <small>
                            <br/>
                            Ответственный <strong>{{ car.creator.name }}</strong>
                          </small>
                        </li>
                        <li class="list-group-item no-border medium-line-height text-muted font-small pt-0"
                            v-if="car.editor">
                          {{ car.updated_date }}
                          <small>
                            <br/>
                            Обновил <strong>{{ car.editor.name }}</strong>
                          </small>
                        </li>
                      </ul>
                    </div>
                    <div class="col-12 col-lg-7">
                      <ul class="list-group list-group-unbordered">
                        <li class="list-group-item pb-1">
                          <strong>{{ car.branch.title }}</strong>
                          <small v-if="car.branch.address">
                            ({{ car.branch.address }})
                          </small>
                          <small style="letter-spacing: -.035rem;">
                            <strong v-if="car.specification.engine_title" class="text-black font-small">
                              <span class="font-light">/</span> {{ car.specification.engine_title }}
                            </strong>
                            / {{ car.specification.fuel.title }}
                            / {{ car.specification.gearbox.title }}
                            / {{ car.specification.race }} тыс. км.
                            / {{ car.specification.transmission.title }} привод
                            / {{ car.specification.color.title }}
                            <span v-if="car.origin">/ {{ car.origin.title }}</span>
                            / <strong class="text-black font-small">{{ car.year }}</strong> г.
                          </small>
                        </li>
                        <li class="list-group-item" style="border-bottom: 0; padding-bottom: 0; text-align: right;">
                          <clipboard-block :data="car.specification.reg_number" custom_class="btn-default"/>
                          <span class="label label-primary margin-r-5">
                            <strong style="font-size: 15px; letter-spacing: .03rem;">
                              {{ car.specification.reg_number }}
                            </strong>
                          </span>

                          <span class="label label-primary margin-l-5">
                            <strong style="font-size: 14px; letter-spacing: .001rem;">
                              {{ car.specification.vin_code }}
                            </strong>
                          </span>
                          <clipboard-block :data="car.specification.vin_code" custom_class="btn-default"/>
                        </li>
                        <li class="list-group-item no-border font-small"
                            style="text-align: right;">
                          <i class="fa fa-eye text-muted font-light" style="margin-right: 2px;"/> {{ car.views }}
                        </li>
                      </ul>
                    </div>
                    <div v-if="car.sold"
                         class="col-12"
                         :style="car.sold ? 'background-color: rgba(40, 167, 69, 0.25) !important' : ''">
                      Продано за
                      <strong class="font-scale-1">
                        {{ car.sold.sum }}$
                      </strong>
                      владельцу
                      <strong class="font-scale-1">
                        {{ car.sold.owner_sum }}$
                      </strong>
                      комиссия
                      <strong class="font-scale-1">
                        {{ (car.sold.sum - car.sold.owner_sum) }}$
                      </strong>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-lg-12 pt-2">
                      <router-link :to="{ name: 'car.details', params: { carId: car.id }}"
                                   class="btn btn-success pull-right margin-l-5"
                                   title="Детали"
                                   type="button">
                        <i class="fa fa-info-circle"></i>
                      </router-link>

                      <router-link :to="{ name: 'car.details', params: { carId: car.id, currentTab: 'Акты осмотра' }}"
                                   class="btn btn-info pull-right margin-l-5"
                                   title="Акты осмотра"
                                   type="button">
                        <i class="fa fa-screwdriver-wrench"></i>
                      </router-link>

                      <a v-if="!car.is_disabled" :href="$clientAppUrl + '/car/' + car.slug" :title="car.slug"
                         style="font-size: 1rem; margin-left: 6px;"
                         class="btn btn-info pull-right" target="_blank">
                        <i class="fas fa-external-link-square-alt"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <template v-if="type && type_status">
                <div class="row" v-if="typeof form.details.is_official_employment !== 'undefined' ||
                  typeof form.details.credit_terms_year !== 'undefined' ||
                  typeof form.details.initial_fee_percent !== 'undefined'">
                  <div class="col-xl-4 col-md-12"
                       v-bind:class="{ 'has-error': form.errors.has('details.is_official_employment') }">
                    <div class="form-group mt-2">
                      <label>
                        Оф. трудоустройство
                        <input class="form-check-input margin-l-5" type="checkbox"
                               v-model="form.details.is_official_employment">
                        <HasError :form="form" field="details.is_official_employment"/>
                      </label>
                    </div>
                  </div>
                  <div class="col-xl-4 col-md-12"
                       v-bind:class="{ 'has-error': form.errors.has('details.credit_terms_year') }">
                    <div class="form-group">
                      <label>
                        <select class="form-control" v-model="form.details.credit_terms_year">
                          <option :value="null" class="text-muted">
                            Срок кредита, лет
                          </option>
                          <option :value="year"
                                  v-for="year in [1, 2, 3, 4, 5]"
                                  :selected="form.details.credit_terms_year === year">
                            {{ year }}
                          </option>
                        </select>
                        <HasError :form="form" field="details.credit_terms_year"/>
                      </label>
                    </div>
                  </div>
                  <div class="col-xl-4 col-md-12"
                       v-bind:class="{ 'has-error': form.errors.has('details.initial_fee_percent') }">
                    <div class="form-group">
                      <label>
                        Аванс <strong>{{ form.details.initial_fee_percent }}</strong>%
                        <input type="range" class="form-range" min="25" max="60" step="1"
                               v-model="form.details.initial_fee_percent">
                        <HasError :form="form" field="details.initial_fee_percent"/>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="row" v-if="typeof form.details.mark !== 'undefined' ||
                  typeof form.details.model !== 'undefined'">
                  <div class="col-xl-6 col-md-12" v-bind:class="{ 'has-error': form.errors.has('details.mark') }">
                    <div class="form-group">
                      <label>
                        <multiselect :allow-empty="true"
                                     :options="marks"
                                     :searchable="true"
                                     @input="() => {
                                       form.details.mark = mark ? mark : null;
                                     }"
                                     @select="getCarModels"
                                     deselect-label="Убрать"
                                     label="name"
                                     placeholder="Марка"
                                     select-label="Выбрать"
                                     selected-label="Выбрано"
                                     title="Марка"
                                     track-by="id"
                                     v-model="mark">
                          <template slot="noResult">Не найдено</template>
                          <template slot="noOptions">Данные отсутствуют</template>
                        </multiselect>
                        <HasError :form="form" field="details.mark"/>
                      </label>
                    </div>
                  </div>
                  <div class="col-xl-6 col-md-12" v-bind:class="{ 'has-error': form.errors.has('details.model') }">
                    <div class="form-group">
                      <label>
                        <multiselect :allow-empty="true"
                                     :disabled="models.length < 1"
                                     :options="models"
                                     :searchable="true"
                                     @input="() => {
                                       form.details.model = model ? model : null;
                                     }"
                                     deselect-label="Убрать"
                                     label="name"
                                     placeholder="Модель"
                                     select-label="Выбрать"
                                     selected-label="Выбрано"
                                     title="Модель"
                                     track-by="id"
                                     v-model="model">
                          <template slot="noResult">Не найдено</template>
                          <template slot="noOptions">Данные отсутствуют</template>
                        </multiselect>
                        <HasError :form="form" field="details.model"/>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="row" v-if="typeof form.details.year !== 'undefined' ||
                  typeof form.details.race !== 'undefined' ||
                  typeof form.details.link !== 'undefined'">
                  <div class="col-xl-4 col-md-12" v-if="typeof form.details.year !== 'undefined'"
                       v-bind:class="{ 'has-error': form.errors.has('details.year') }">
                    <div class="form-group">
                      <label>
                        <input :max="2023"
                               :min="1900"
                               class="form-control"
                               name="year"
                               placeholder="Год выпуска"
                               title="Год выпуска"
                               type="number"
                               v-model="form.details.year">
                        <HasError :form="form" field="details.year"/>
                      </label>
                    </div>
                  </div>
                  <div class="col-xl-4 col-md-12" v-if="typeof form.details.race !== 'undefined'"
                       v-bind:class="{ 'has-error': form.errors.has('details.race') }">
                    <div class="form-group">
                      <label>
                        <input :max="9999999"
                               :min="1"
                               class="form-control"
                               name="race"
                               placeholder="Пробег"
                               title="Пробег"
                               type="number"
                               v-model="form.details.race">
                        <HasError :form="form" field="details.race"/>
                      </label>
                    </div>
                  </div>
                  <div class="col-xl-4 col-md-12" v-if="typeof form.details.link !== 'undefined'"
                       v-bind:class="{ 'has-error': form.errors.has('details.link') }">
                    <div class="form-group">
                      <label>
                        <input class="form-control"
                               name="link"
                               placeholder="Ссылка на авто"
                               title="Ссылка на авто"
                               type="text"
                               v-model="form.details.link">
                        <HasError :form="form" field="details.link"/>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="row" v-if="typeof form.details.year_from !== 'undefined' ||
                  typeof form.details.year_to !== 'undefined' || typeof form.details.race_from !== 'undefined' ||
                  typeof form.details.race_to !== 'undefined'">
                  <div class="col-xl-3 col-md-6 col-md-12" v-if="typeof form.details.year_from !== 'undefined'"
                       v-bind:class="{ 'has-error': form.errors.has('details.year_from') }">
                    <div class="form-group">
                      <label>
                        <input :max="2023"
                               :min="1900"
                               class="form-control"
                               name="year_from"
                               placeholder="Год, от"
                               title="Год, от"
                               type="number"
                               v-model="form.details.year_from">
                        <HasError :form="form" field="details.year_from"/>
                      </label>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-6 col-md-12" v-if="typeof form.details.year_to !== 'undefined'"
                       v-bind:class="{ 'has-error': form.errors.has('details.year_to') }">
                    <div class="form-group">
                      <label>
                        <input :max="2023"
                               :min="1900"
                               class="form-control"
                               name="year_to"
                               placeholder="Год, до"
                               title="Год, до"
                               type="number"
                               v-model="form.details.year_to">
                        <HasError :form="form" field="details.year_to"/>
                      </label>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-6 col-md-12" v-if="typeof form.details.race_from !== 'undefined'"
                       v-bind:class="{ 'has-error': form.errors.has('details.race_from') }">
                    <div class="form-group">
                      <label>
                        <input :max="9999999"
                               :min="1"
                               class="form-control"
                               name="race_from"
                               placeholder="Пробег, от"
                               title="Пробег, от"
                               type="number"
                               v-model="form.details.race_from">
                        <HasError :form="form" field="details.race_from"/>
                      </label>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-6 col-md-12" v-if="typeof form.details.race_to !== 'undefined'"
                       v-bind:class="{ 'has-error': form.errors.has('details.race_to') }">
                    <div class="form-group">
                      <label>
                        <input :max="9999999"
                               :min="1"
                               class="form-control"
                               name="race_to"
                               placeholder="Пробег, до"
                               title="Пробег, до"
                               type="number"
                               v-model="form.details.race_to">
                        <HasError :form="form" field="details.race_to"/>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="row" v-if="typeof form.details.color !== 'undefined' ||
                  typeof form.details.gearbox !== 'undefined' || typeof form.details.price_from !== 'undefined' ||
                  typeof form.details.price_to !== 'undefined'">
                  <div class="col-xl-3 col-md-6 col-md-12" v-if="typeof form.details.color !== 'undefined'"
                       v-bind:class="{ 'has-error': form.errors.has('details.color') }">
                    <div class="form-group">
                      <label>
                        <multiselect :allow-empty="true"
                                     :options="colors"
                                     :searchable="true"
                                     @input="() => {
                                       form.details.color = color ? color : null;
                                     }"
                                     deselect-label="Убрать"
                                     label="title"
                                     placeholder="Цвет"
                                     select-label="Выбрать"
                                     selected-label="Выбрано"
                                     title="Цвет"
                                     track-by="id"
                                     v-model="color">
                          <template slot="noResult">Не найдено</template>
                          <template slot="noOptions">Данные отсутствуют</template>
                        </multiselect>
                        <HasError :form="form" field="details.color"/>
                      </label>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-6 col-md-12" v-if="typeof form.details.gearbox !== 'undefined'"
                       v-bind:class="{ 'has-error': form.errors.has('details.gearbox') }">
                    <div class="form-group">
                      <label>
                        <multiselect :allow-empty="false"
                                     :options="gearboxes"
                                     :searchable="true"
                                     @input="() => {
                                       form.details.gearbox = gearbox ? gearbox : null;
                                     }"
                                     deselect-label="Убрать"
                                     label="title"
                                     placeholder="КПП"
                                     select-label="Выбрать"
                                     selected-label="Выбрано"
                                     title="КПП"
                                     track-by="id"
                                     v-model="gearbox">
                          <template slot="noResult">Не найдено</template>
                          <template slot="noOptions">Данные отсутствуют</template>
                        </multiselect>
                        <HasError :form="form" field="details.gearbox"/>
                      </label>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-6 col-md-12" v-if="typeof form.details.price_from !== 'undefined'"
                       v-bind:class="{ 'has-error': form.errors.has('details.price_from') }">
                    <div class="form-group">
                      <label>
                        <input :max="999999999"
                               :min="1"
                               class="form-control"
                               name="price_from"
                               placeholder="Цена, от"
                               title="Цена, от"
                               type="number"
                               v-model="form.details.price_from">
                        <HasError :form="form" field="details.price_from"/>
                      </label>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-6 col-md-12" v-if="typeof form.details.price_to !== 'undefined'"
                       v-bind:class="{ 'has-error': form.errors.has('details.price_to') }">
                    <div class="form-group">
                      <label>
                        <input :max="999999999"
                               :min="1"
                               class="form-control"
                               name="price_to"
                               placeholder="Цена, до"
                               title="Цена, до"
                               type="number"
                               v-model="form.details.price_to">
                        <HasError :form="form" field="details.price_to"/>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="row" v-if="typeof form.details.bargain_sum !== 'undefined'">
                  <div class="col-xl-3 col-md-6 col-md-12"
                       v-bind:class="{ 'has-error': form.errors.has('details.bargain_sum') }">
                    <div class="form-group">
                      <label>
                        <input :max="999999999"
                               :min="1"
                               class="form-control"
                               name="bargain_sum"
                               placeholder="Сумма торга"
                               title="Сумма торга"
                               type="number"
                               v-model="form.details.bargain_sum">
                        <HasError :form="form" field="details.bargain_sum"/>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="panel">
                  <div class="panel-body">
                    <h4>Задача: {{ type.name }} - {{ type_status.name.toLowerCase() }}</h4>

                    <p v-if="responsible" class="mb-1">
                      <strong>Исполнитель: </strong>
                      <span>{{ responsible.name }} (ID: {{ responsible.id }})</span>
                    </p>
                    <p v-if="car" class="mb-0">
                      <strong>Автомобиль: </strong>
                      <span>{{ car.title }} (ID: {{ car.id }})</span>
                    </p>
                    <p v-if="client || form.client_name" class="mb-1">
                      <strong>Клиент: </strong>
                      <span v-if="client">{{ client.name }}, {{ client.phone }}</span>
                      <span v-else>{{ form.client_name }}, {{ form.client_phone }}</span>
                    </p>

                    <p v-if="form.details.is_official_employment || form.details.credit_terms_year ||
                        form.details.initial_fee_percent" class="mb-0">
                      <span>
                        {{ form.details.is_official_employment ? 'Официально трудоустроен, ' : '' }}
                        {{ form.details.credit_terms_year ? 'срок ' + form.details.credit_terms_year + ' г.,' : '' }}
                        аванс {{ form.details.initial_fee_percent }}%
                      </span>
                    </p>
                    <p v-if="mark || model" class="mt-1 mb-0">
                      <span>
                        <strong>{{ mark ? mark.name : '' }} {{ model ? model.name : '' }}</strong>
                      </span>
                    </p>
                    <p v-if="form.details.year || form.details.race || form.details.link" class="mb-0">
                      <span>
                        {{ form.details.year ? form.details.year + ' год; ' : '' }}
                        {{ form.details.race ? 'пробег ' + form.details.race + ' тыс. км.; ' : '' }}
                        <a v-if="form.details.link" :href="form.details.link" target="_blank">
                          cсылка на авто
                        </a>
                      </span>
                    </p>
                    <p v-if="form.details.year_from || form.details.year_to || form.details.race_from ||
                        form.details.race_to || color || gearbox || form.details.price_from || form.details.price_to"
                       class="mb-0">
                      <span>
                        {{ form.details.year_from ? 'от ' + form.details.year_from + ' г.; ' : '' }}
                        {{ form.details.year_to ? 'до ' + form.details.year_to + ' г.; ' : '' }}
                        {{ form.details.race_from ? 'от ' + form.details.race_from + ' тыс. км.; ' : '' }}
                        {{ form.details.race_to ? 'до ' + form.details.race_to + ' тыс. км.; ' : '' }}
                        {{ color ? 'цвет ' + color.title.toLowerCase() + '; ' : '' }}
                        {{ gearbox ? 'КПП  ' + gearbox.title.toLowerCase() + '; ' : '' }}
                        {{ form.details.price_from ? 'от ' + form.details.price_from + '$; ' : '' }}
                        {{ form.details.price_to ? 'до ' + form.details.price_to + '$; ' : '' }}
                      </span>
                    </p>
                    <p v-if="form.details.bargain_sum" class="mb-0">
                      <span>
                        <strong>Сумма торга: </strong> {{ form.details.bargain_sum }}$
                        — <strong class="font-medium text-muted">
                            {{ car.price - form.details.bargain_sum }}$
                          </strong>
                      </span>
                    </p>

                    <p v-if="form.comment" class="text-muted font-small mt-2 mb-1">
                      {{ form.comment }}
                    </p>
                  </div>
                </div>

              </template>
            </div>
          </div>

          <AlertErrors :form="form" message="Ошибки валидации. Проверьте введённые данные."/>

          <Button :disabled="!validateData || form.busy"
                  :form="form"
                  :loading="form.busy"
                  class="btn btn-lg btn-success pull-right"
                  style="min-width: 100px;">
            <i class="fa fa-save"></i>
          </Button>
        </form>
        <loading-processing v-else></loading-processing>
      </transition>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Form from 'vform';
import {VueTelInput} from 'vue-tel-input';
import phone_config from './../../../assets/data/vue-tel-input-config.json';
import Multiselect from 'vue-multiselect';
import VCalendar from 'v-calendar';
import ClipboardBlock from "@/components/blocks/clipboard-block.vue";

Form.axios = API.apiClient;

Vue.use(VCalendar, {});

export default {
  name: 'task_create',

  components: {
    ClipboardBlock,
    Button, HasError, AlertError, AlertErrors,
    VueTelInput,
    Multiselect,
  },

  metaInfo() {
    return {
      title: this.page_title,
    }
  },

  props: [
    'car',
  ],

  data: () => ({
    page_title: 'Создать задачу',

    form: new Form({
      car_id: null,
      client_id: null,
      client_name: null,
      client_phone: null,
      type_id: null,
      type_status_id: null,
      details: {},
      responsible_id: null,
      comment: null,
    }),

    clients: [],
    client: null,
    types: [],
    type: null,
    types_statuses: [],
    type_status: null,
    employees: [],
    responsible: null,

    marks: [],
    mark: null,
    models: [],
    model: null,
    colors: [],
    color: null,
    gearboxes: [],
    gearbox: null,

    car_relations: [
      'mark',
      'model',
      'branch',
      'creator.branch',
    ],

    onProgress: true,

    phone_config: phone_config,
  }),

  async created() {
    try {
      const [
        clients_response,
        types_response,
        types_statuses_response,
        employees_response,
      ] = await axios.all([
        API.apiClient.get('/clients', {
          params: {
            "is_list_load": 1
          },
        }),
        API.apiClient.get('/tasks/types', {
          params: {
            filters_query: {
              custom_type_ids: [1, 3, 4, 5, 6, 7, 8, 9, 14, 15],
            }
          }
        }),
        API.apiClient.get('/tasks/types/statuses', {
          params: {
            filters_query: {
              custom_type_ids: [10, 11, 12],
            }
          }
        }),
        API.apiClient.get('/employees', {
          params: this.$apiRequestParams.filters_list
        }),
      ]);

      this.clients = clients_response.data;
      this.types = types_response.data;
      this.types_statuses = types_statuses_response.data;
      this.employees = employees_response.data;

      this.form.car_id = this.car.id;
      this.responsible = this.car.creator;
      this.form.responsible_id = this.responsible.id;
    } catch (error) {
      errorHandler(error);
    }

    this.onProgress = false;
  },

  computed: {
    validateData: function () {
      return (this.form.client_id || (this.form.client_name && this.form.client_phone)) && this.form.type_id &&
          this.form.type_status_id;
    },
  },

  methods: {
    checkPhoneOnExist() {
      if (this.form.client_phone.length > 12) {
        let clientPhoneClear = parseInt(this.form.client_phone.replace(/\s/g, ""));
        this.client = this.clients.find((client) => {
          return client.phone === clientPhoneClear;
        });

        if (this.client) {
          this.form.client_name = this.client.name;
        }
      }
    },
    setTaskType() {
      try {
        this.mark = null;
        this.model = null;
        this.models = [];
        this.color = null;
        this.gearbox = null;

        switch (this.type.id) {
          case 1:
            this.form.details = {};
            break;
          case 3:
            this.form.details = {};
            break;
          case 4:
            this.form.details = {};
            break;
          case 5:
            this.form.details = {
              mark: null,
              model: null,
              year_from: null,
              year_to: null,
              race_from: null,
              race_to: null,
              color: null,
              gearbox: null,
              price_from: null,
              price_to: null,
            };
            if (this.marks.length < 1) {
              this.getCarMarks();
            }
            if (this.colors.length < 1) {
              this.getCarColors();
            }
            if (this.gearboxes.length < 1) {
              this.getCarGearboxes();
            }
            break;
          case 6:
            this.form.details = {
              is_official_employment: false,
              credit_terms_year: null,
              initial_fee_percent: 25,
            };
            break;
          case 7:
            this.form.details = {
              mark: null,
              model: null,
              year: null,
              race: null,
              link: null,
            };
            if (this.marks.length < 1) {
              this.getCarMarks();
            }
            break;
          case 8:
            this.form.details = {};
            break;
          case 9:
            this.form.details = {
              bargain_sum: null,
            };
            break;
          case 14:
            this.form.details = {};
            break;
        }
      } catch (error) {
        errorHandler(error);
      }
    },

    async getCarMarks() {
      try {
        const marks_response = await API.apiClient.get('/cars/marks', {
          params: {
            is_paginated: 0,
            sorting: 'asc',
            sort_column: 'name'
          }
        });
        this.marks = marks_response.data;
      } catch (error) {
        errorHandler(error);
      }
    },
    async getCarModels(mark) {
      try {
        this.models = [];
        const models_response = await API.apiClient.get(
            '/cars/mark/' + mark.id + '/models', {
              params: {
                is_paginated: 0,
                sorting: 'asc',
                sort_column: 'name',
              }
            }
        );
        this.models = models_response.data;
      } catch (error) {
        errorHandler(error);
      }
    },
    async getCarColors() {
      try {
        const colors_response = await API.apiClient.get('/cars/colors');
        this.colors = colors_response.data;
      } catch (error) {
        errorHandler(error);
      }
    },
    async getCarGearboxes() {
      try {
        const gearboxes_response = await API.apiClient.get('/cars/gearboxes');
        this.gearboxes = gearboxes_response.data;
      } catch (error) {
        errorHandler(error);
      }
    },

    async saveTask() {
      await this.form.post('/tasks/add').then(() => {
        showSuccess();
        setTimeout(() => this.$router.push('/task/cars'), 600);
      }).catch((error) => {
        errorHandler(error);
      });
    },
  }
}
</script>

<style scoped></style>

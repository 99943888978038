<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block/>

        {{ page_title }}
      </h3>
    </div>

    <div class="box-header with-border">
      <h5 class="text-bold">
        Входящие задачи
      </h5>
    </div>

    <div class="box-body">
      <incoming-new-tasks/>
    </div>

    <div class="box-header with-border">
      <h5 class="text-bold">
        Статистика задач
      </h5>
    </div>

    <div class="box-body">
      <tasks-employees-statistics/>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

Vue.component('incoming-new-tasks', (resolve) => {
  return require(['./../funnels/new/index'], resolve);
});
Vue.component('tasks-employees-statistics', (resolve) => {
  return require(['./employees-statistics'], resolve);
});

export default {
  name: 'tasks-reports',

  components: {},

  props: {
    data_search: {
      type: String,
      required: false
    },
  },

  metaInfo() {
    return {
      title: this.page_title,
    }
  },

  data: () => ({
    page_title: 'Отчёты',

    relations: [
      'car.branch',
      'client',
      'type',
      'branch',
      'last_comment.creator',
      'creator',
      'editor',
    ],
    relations_count: [
      'comments',
    ],
  }),
}
</script>

<style scoped>

</style>

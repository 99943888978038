<template>
  <transition name="fade">
    <form @keydown="form.onKeydown($event)" @submit.prevent="savePersonalTask"
          v-if="onProgress === false" class="mb-5">
      <div class="row">
        <div class="col-xl-6 col-md-12">
          <div class="row">
            <div class="col-md-12" v-bind:class="{ 'has-error': form.errors.has('responsible_id') }">
              <div class="form-group">
                <label for="responsible_id">
                  <multiselect :allow-empty="false"
                               :options="employees"
                               :searchable="true"
                               id="responsible_id"
                               @input="() => {
                                 form.responsible_id = responsible ? responsible.id : null;
                               }"
                               deselect-label="Убрать"
                               label="title"
                               placeholder="Ответственный"
                               required
                               select-label="Выбрать"
                               selected-label="Выбрано"
                               title="Ответственный"
                               track-by="id"
                               v-model="responsible">
                    <template slot="noResult">Не найдено</template>
                    <template slot="noOptions">Данные отсутствуют</template>
                  </multiselect>
                  <HasError :form="form" field="responsible_id"/>
                </label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12" v-bind:class="{ 'has-error': form.errors.has('comment') }">
              <div class="form-group">
                    <textarea class="form-control"
                              maxlength="3000"
                              id="description"
                              rows="5"
                              placeholder="Комментарий"
                              v-model="form.comment">
                    </textarea>
                <HasError :form="form" field="comment"/>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-6 col-md-12">
          <div class="row">
            <div class="col-12" v-bind:class="{ 'has-error': form.errors.has('tags') }">
              <div class="form-group">
                <label for="tags">
                  <multiselect :allow-empty="true"
                               :multiple="true"
                               :options="tags"
                               :searchable="false"
                               id="tags"
                               deselect-label="Убрать"
                               label="name"
                               placeholder="Теги"
                               required
                               select-label="Выбрать"
                               selected-label="Выбрано"
                               title="Теги"
                               track-by="id"
                               v-model="form.tags">
                    <template slot="noResult">Не найдено</template>
                    <template slot="noOptions">Данные отсутствуют</template>
                  </multiselect>
                  <HasError :form="form" field="tags"/>
                </label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-6 col-md-12" v-bind:class="{ 'has-error': form.errors.has('term_at') }">
              <div class="form-group">
                <label>
                  <v-date-picker
                      :masks="date_options.masks"
                      :max-date="date_options.maxDate"
                      :min-date="date_options.minDate"
                      :mode="'date'"
                      :model-config="date_options.formatDate"
                      :update-on-input="false"
                      color="blue"
                      locale="ru"
                      v-model="form.term_at"
                      trim-weeks>
                    <template v-slot="{inputValue, inputEvents}">
                      <input :value="inputValue"
                             class="form-control"
                             placeholder="Дата выполнения"
                             v-on="inputEvents"/>
                    </template>
                  </v-date-picker>
                  <HasError :form="form" field="term_at"/>
                </label>
              </div>
            </div>
            <div class="col-xl-6 col-md-12" v-bind:class="{ 'has-error': form.errors.has('term_time') }">
              <div class="form-group">
                <label for="term_time">
                  <input type="time" class="form-control" id="term_time" name="term_time" v-model="form.term_time" required>
                </label>
                <HasError :form="form" field="term_time"/>
              </div>
            </div>
          </div>

          <div class="panel" v-if="responsible">
            <div class="panel-body">
              <h4>Личная задача</h4>

              <p v-if="responsible" class="mb-1">
                <strong>Исполнитель: </strong>
                <span>{{ responsible.name }} (ID: {{ responsible.id }})</span>
              </p>

              <p v-if="form.term_at" class="mb-0">
                <span><strong>Срок выполнения: </strong> {{ form.term_at }} {{ form.term_time }}</span>
              </p>

              <p v-if="form.comment" class="text-muted font-small mt-2 mb-1">
                {{ form.comment }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <AlertErrors :form="form" message="Ошибки валидации. Проверьте введённые данные."/>

      <Button :disabled="!validateData || form.busy"
              :form="form"
              :loading="form.busy"
              class="btn btn-lg btn-success pull-right"
              style="min-width: 100px;">
        <i class="fa fa-save"></i>
      </Button>
    </form>
    <loading-processing v-else></loading-processing>
  </transition>
</template>

<script>
import Vue from "vue";
import Form from 'vform';
import Multiselect from 'vue-multiselect';
import moment from 'moment';
import VCalendar from 'v-calendar';

Form.axios = API.apiClient;

Vue.use(VCalendar, {});

moment.locale('ru');

const DATE_FORMAT = 'YYYY-MM-DD';

export default {
  name: 'task_create_personal',

  components: {
    Button, HasError, AlertError, AlertErrors,
    Multiselect,
  },

  data: () => ({
    form: new Form({
      type_id: 101,
      type_status_id: 1,
      tags: null,
      responsible_id: null,
      comment: null,
      term_at: null,
      term_time: '00:00',
      is_personal_task: true,
    }),

    tags: [],
    employees: [],
    responsible: null,

    onProgress: true,

    date_options: {
      minDate: moment().format(DATE_FORMAT),
      maxDate: moment().add(3, 'M').format(DATE_FORMAT),
      formatDate: {
        type: 'string',
        mask: DATE_FORMAT,
      },
      masks: {
        input: DATE_FORMAT,
      },
    },
  }),

  async created() {
    try {
      const [tags_response, employees_response] = await axios.all([
        API.apiClient.get('/tasks/tags'),
        API.apiClient.get('/employees', {
          params: this.$apiRequestParams.filters_list
        }),
      ]);

      this.tags = tags_response.data;
      this.employees = employees_response.data;
    } catch (error) {
      errorHandler(error);
    }

    this.onProgress = false;
  },

  computed: {
    validateData: function () {
      return this.form.type_id && this.form.responsible_id && this.form.comment && this.form.comment.length > 0;
    },
  },

  methods: {
    async savePersonalTask() {
      await this.form.post('/tasks/add').then(() => {
        showSuccess();
        setTimeout(() => this.$router.push('/tasks/funnel/personal'), 500);
      }).catch((error) => {
        errorHandler(error);
      });
    },
  }
}
</script>

<style scoped></style>